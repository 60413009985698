.bonus {
  &__text {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
  }

  &__table {
    overflow: auto;
    border-radius: 3.5rem;
    padding: 1rem;
    box-shadow: -20px 35px 50px rgb(14 8 17 / 80%);
    background-color: var(--clr-primary);

    table {
      overflow: hidden;
      border-collapse: collapse;
      min-width: 60rem;
      font-family: "Gilroy", sans-serif;
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 90%);
    }

    thead {
      font-weight: 600;
      background-color: var(--clr-bg);

      td:last-child {
        border-top-right-radius: 3rem;
      }

      td:first-child {
        border-top-left-radius: 3rem;
      }
    }

    tbody {
      td {
        vertical-align: top;
        line-height: 135%;

        p + p {
          margin-top: 1.5rem;
        }
      }
    }

    td {
      padding: 2rem 4rem;

      @media (max-width: em(992)) {
        padding: 1.5rem;
      }
    }
  }
}
