.info {
  padding-top: 12rem;

  @media (max-width: em(767)) {
    padding-top: 6rem;
  }

  &__title {
    margin-bottom: 4rem;

    @media (max-width: em(992)) {
      margin-bottom: 2rem;
    }
  }

  &__content {
    overflow: auto;
    border-radius: 3.5rem;
    padding: 1rem;
    background-color: var(--clr-primary);
  }

  &__table {
    overflow: hidden;
    min-width: 70rem;
    font-family: "Gilroy", sans-serif;
    font-size: 1.4rem;
    line-height: 135%;
    color: rgb(255 255 255 / 90%);

    &-row {
      display: flex;

      &:not(:last-child) {
        .info__table-left,
        .info__table-right {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: calc(100% - 5rem);
            height: 0.1rem;
            background-color: rgb(255 255 255 / 8%);
            transform: translateX(-50%);
            pointer-events: none;
          }
        }
      }

      &:first-child {
        .info__table-left {
          border-top-left-radius: 3rem;
        }
      }

      &:last-child {
        .info__table-left {
          border-bottom-left-radius: 3rem;
        }
      }
    }

    &-left {
      padding: 2rem 3.5rem;
      width: 100%;
      max-width: 23rem;
      background-color: var(--clr-bg);

      @media (max-width: em(992)) {
        padding: 1.5rem 2.5rem;
      }
    }

    &-right {
      padding: 2rem 3.5rem;
      width: 100%;

      @media (max-width: em(992)) {
        padding: 1.5rem 2.5rem;
      }
    }
  }
}
