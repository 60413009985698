.review {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 57rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__text {
    margin-top: 2rem;
    margin-bottom: 5.5rem;

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__img {
    position: relative;
    max-width: 55.2rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 30%;
      z-index: -1;
      width: 50rem;
      height: 31rem;
      background-color: #342840;
      filter: blur(160px);
      transform: rotate(42.74deg) translateY(-30%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
