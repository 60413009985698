/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.input-reset::-webkit-search-decoration,
.input-reset::-webkit-search-cancel-button,
.input-reset::-webkit-search-results-button,
.input-reset::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Roboto", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: rgba(255, 255, 255, 0.7);
  --clr-default-200: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;
  --clr-primary: #221728;
  --clr-bg: #170e1c;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-200);
  background-color: var(--clr-bg);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

/* stylelint-disable no-invalid-position-at-import-rule */

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -0.1rem;
  border: 0;
  padding: 0;
  width: 0.1rem;
  height: 0.1rem;
  clip: rect(0 0 0 0);
}

.section__banner {
  margin: 2rem auto;
  max-width: 97rem;
}

.section__pd--t65 {
  padding-top: 6.5rem;
}

.section__pd--b65 {
  padding-bottom: 6.5rem;
}

.section__mr--t20 {
  margin-top: 2rem;
}

.section__mr--b40 {
  margin-bottom: 4rem;
}

.section__title {
  font-weight: 500;
  line-height: 155%;
}

.section__title--small {
  font-size: clamp(
    2rem,
    1.8545454545rem + 0.4545454545vw,
    2.4rem
  );
}

.section__title--large {
  font-size: clamp(
    2.4rem,
    1.9636363636rem + 1.3636363636vw,
    3.6rem
  );
}

.section__text {
  line-height: 155%;
  color: var(--clr-default-100);
}

.section__text p + p {
  margin-top: 1.5rem;
}

.section__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  height: 5.5rem;
  min-width: 23.5rem;
  font-weight: 500;
  line-height: 155%;
  color: #1f1f20;
  background-image: linear-gradient(109.45deg, #ffcb6c 22.4%, #e2a433 77.68%);
  transition: box-shadow var(--transition);
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item + .menu__item {
  margin-left: 4rem;
}

.menu__link {
  color: var(--clr-default-100);
  position: relative;
  display: block;
}

.menu__link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.2rem;
  width: 0;
  height: 0.1rem;
  background-color: var(--clr-default-100);
  transform: translateX(-50%);
  transition: width var(--transition);
}

.logo {
  max-width: 13rem;
}

.burger {
  display: none;
}

.header {
  position: absolute;
  width: 100%;
  padding-top: 1rem;
  z-index: 10;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__wrapper {
  display: flex;
}

.faq__list {
  margin-top: 6rem;
}

.faq__item {
  transition: padding-bottom var(--transition);
}

.faq__item-title {
  position: relative;
  border-radius: 0.7rem;
  padding: 2.2rem 7rem 2.2rem 4.5rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  background-color: var(--clr-primary);
  cursor: pointer;
}

.faq__item-title::before,
.faq__item-title::after {
  content: "";
  position: absolute;
  background-color: #f7c746;
}

.faq__item-title::before {
  right: 4.5rem;
  width: 2rem;
  height: 0.2rem;
  top: 3.2rem;
}

.faq__item-title::after {
  right: 5.4rem;
  width: 0.2rem;
  top: 2.2rem;
  height: 2rem;
  transition: height var(--transition);
}

.faq__item-body {
  overflow: hidden;
  padding-right: 4.5rem;
  padding-left: 4.5rem;
  max-height: 0;
  box-sizing: content-box;
  line-height: 155%;
  color: var(--clr-default-100);
  background-color: var(--clr-bg);
  transition: max-height var(--transition), padding-top var(--transition), padding-bottom var(--transition);
}

.faq__item--active .faq__item-body {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  transition: padding-top var(--transition), padding-bottom var(--transition);
}

.faq__item--active .faq__item-title::after {
  height: 0;
  transition: height var(--transition);
}

.faq__item + .faq__item {
  margin-top: 3rem;
}

.hero {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 17rem;
  background-color: #06050a;
}

.hero__info {
  z-index: 1;
  max-width: 57rem;
}

.hero__title {
  font-weight: 700;
  font-size: clamp(
    3.4rem,
    3.1090909091rem + 0.9090909091vw,
    4.2rem
  );
}

.hero__text {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.hero__img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-width: 59rem;
}

.info {
  padding-top: 12rem;
}

.info__title {
  margin-bottom: 4rem;
}

.info__content {
  overflow: auto;
  border-radius: 3.5rem;
  padding: 1rem;
  background-color: var(--clr-primary);
}

.info__table {
  overflow: hidden;
  min-width: 70rem;
  font-family: "Gilroy", sans-serif;
  font-size: 1.4rem;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.9);
}

.info__table-row {
  display: flex;
}

.info__table-row:not(:last-child) .info__table-left,
.info__table-row:not(:last-child) .info__table-right {
  position: relative;
}

.info__table-row:not(:last-child) .info__table-left::before,
.info__table-row:not(:last-child) .info__table-right::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.08);
  transform: translateX(-50%);
  pointer-events: none;
}

.info__table-row:first-child .info__table-left {
  border-top-left-radius: 3rem;
}

.info__table-row:last-child .info__table-left {
  border-bottom-left-radius: 3rem;
}

.info__table-left {
  padding: 2rem 3.5rem;
  width: 100%;
  max-width: 23rem;
  background-color: var(--clr-bg);
}

.info__table-right {
  padding: 2rem 3.5rem;
  width: 100%;
}

.review__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review__info {
  margin-right: 1.5rem;
  max-width: 57rem;
}

.review__text {
  margin-top: 2rem;
  margin-bottom: 5.5rem;
}

.review__img {
  position: relative;
  max-width: 55.2rem;
}

.review__img::before {
  content: "";
  position: absolute;
  left: 0;
  top: 30%;
  z-index: -1;
  width: 50rem;
  height: 31rem;
  background-color: #342840;
  filter: blur(160px);
  transform: rotate(42.74deg) translateY(-30%);
}

.pros__wrapper {
  display: flex;
  justify-content: space-between;
}

.pros__text {
  margin-top: 1.5rem;
  margin-bottom: 6rem;
}

.pros__item {
  position: relative;
  flex: 0 1 49%;
  overflow: hidden;
  border-radius: 3.5rem;
  padding: 11.5rem 4rem 5.5rem;
  box-shadow: -20px 35px 50px rgba(14, 8, 17, 0.8);
  background-color: var(--clr-primary);
}

.pros__item::before {
  content: "";
  position: absolute;
  right: -4rem;
  bottom: -12rem;
  width: 43rem;
  height: 43rem;
  filter: blur(160px);
}

.pros__item li {
  position: relative;
  padding-left: 1.8rem;
}

.pros__item li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.3rem;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
}

.pros__item li + li {
  margin-top: 2.5rem;
}

.pros__item--pros::before {
  background-color: rgba(104, 242, 60, 0.11);
}

.pros__item--pros .pros__item--label {
  background-color: rgba(87, 167, 61, 0.3);
}

.pros__item--pros li::before {
  background-color: #57a73d;
}

.pros__item--cons::before {
  background-color: rgba(242, 125, 60, 0.11);
}

.pros__item--cons .pros__item--label {
  background-color: rgba(167, 86, 61, 0.2);
}

.pros__item--cons li::before {
  background-color: #a7563d;
}

.pros__item--label {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 3.5rem;
  border-bottom-right-radius: 2rem;
  width: 13rem;
  height: 8rem;
  font-weight: 600;
  font-size: clamp(
    2rem,
    1.8545454545rem + 0.4545454545vw,
    2.4rem
  );
}

.register__text {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.register__list {
  counter-reset: section;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 4rem;
}

.register__item {
  position: relative;
  border-radius: 2.2rem;
  padding: 13rem 3.5rem 3.5rem;
  min-height: 26rem;
  box-shadow: -15px 30px 40px rgba(14, 8, 17, 0.6);
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 125%;
  background-color: var(--clr-primary);
}

.register__item::before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  left: 3.5rem;
  top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  width: 6rem;
  height: 6rem;
  box-shadow: 0 15px 35px rgba(226, 164, 51, 0.2);
  font-weight: 700;
  font-size: clamp(
    2rem,
    1.8545454545rem + 0.4545454545vw,
    2.4rem
  );
  color: #1f1f20;
  background-image: linear-gradient(109.45deg, #ffcb6c 22.4%, #e2a433 77.68%);
}

.specifications__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specifications__info {
  margin-right: 1.5rem;
  max-width: 57rem;
}

.specifications__content {
  overflow: auto;
  border-radius: 3.5rem;
  padding: 1rem;
  background-color: var(--clr-primary);
}

.specifications__table {
  min-width: 50rem;
  font-family: "Gilroy", sans-serif;
  font-size: 1.4rem;
  line-height: 135%;
}

.specifications__table-row {
  display: flex;
}

.specifications__table-row:not(:last-child) .specifications__table-left,
.specifications__table-row:not(:last-child) .specifications__table-right {
  position: relative;
}

.specifications__table-row:not(:last-child) .specifications__table-left::before,
.specifications__table-row:not(:last-child) .specifications__table-right::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.08);
  transform: translateX(-50%);
  pointer-events: none;
}

.specifications__table-row:first-child .specifications__table-left {
  border-top-left-radius: 3rem;
}

.specifications__table-row:last-child .specifications__table-left {
  border-bottom-left-radius: 3rem;
}

.specifications__table-left {
  padding: 1.5rem 3rem;
  width: 100%;
  max-width: 16.5rem;
  background-color: var(--clr-bg);
}

.specifications__table-right {
  padding: 1.5rem 3rem;
  width: 100%;
}

.installation__wrapper {
  border-radius: 2.2rem;
  padding: 4.5rem;
  box-shadow: -15px 30px 40px rgba(14, 8, 17, 0.6);
  background-color: var(--clr-primary);
}

.installation__text {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.installation .section__btn {
  height: 4.7rem;
  min-width: 19.4rem;
}

.bonus__text {
  margin-top: 4rem;
  margin-bottom: 4.5rem;
}

.bonus__table {
  overflow: auto;
  border-radius: 3.5rem;
  padding: 1rem;
  box-shadow: -20px 35px 50px rgba(14, 8, 17, 0.8);
  background-color: var(--clr-primary);
}

.bonus__table table {
  overflow: hidden;
  border-collapse: collapse;
  min-width: 60rem;
  font-family: "Gilroy", sans-serif;
  font-size: 1.4rem;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.9);
}

.bonus__table thead {
  font-weight: 600;
  background-color: var(--clr-bg);
}

.bonus__table thead td:last-child {
  border-top-right-radius: 3rem;
}

.bonus__table thead td:first-child {
  border-top-left-radius: 3rem;
}

.bonus__table tbody td {
  vertical-align: top;
  line-height: 135%;
}

.bonus__table tbody td p + p {
  margin-top: 1.5rem;
}

.bonus__table td {
  padding: 2rem 4rem;
}

.download__text {
  margin-top: 4rem;
}

.download__link {
  position: relative;
  display: inline-block;
  font-weight: 500;
  line-height: 100%;
  color: var(--clr-default-200);
}

.download__link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.2rem;
  width: 0;
  height: 0.2rem;
  background-color: var(--clr-default-200);
  transform: translateX(-50%);
  transition: width var(--transition);
}

.version__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.version__info {
  margin-right: 1.5rem;
  max-width: 57rem;
}

.version__text {
  margin-top: 2rem;
  margin-bottom: 5.5rem;
}

.version__img {
  max-width: 53.9rem;
}

.reviews__list {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 5rem;
}

.reviews__item {
  position: relative;
  border-radius: 2rem;
  padding: 3.5rem 3rem;
  min-height: 26rem;
  box-shadow: -15px 30px 40px rgba(14, 8, 17, 0.6);
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  line-height: 150%;
  background-color: var(--clr-primary);
}

.reviews__item::before {
  content: "";
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  width: 9rem;
  height: 6.2rem;
  background-image: url("../img/ui/decor.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vip__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vip__info {
  margin-right: 1.5rem;
  max-width: 57rem;
}

.vip__img {
  position: relative;
  max-width: 50.4rem;
}

.vip__img::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  width: 43rem;
  height: 25.5rem;
  background-color: rgba(116, 40, 100, 0.7);
  filter: blur(160px);
  transform: translate(-50%, -50%);
}

.games__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.games__left {
  flex: 0 1 43%;
  margin-right: 1.5rem;
}

.games__right {
  flex: 0 1 54%;
}

.games__undertitle {
  margin-bottom: 2.5rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 125%;
}

.games .bonus__table td {
  padding: 2rem 3rem;
}

.live__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.live__info {
  margin-right: 1.5rem;
  max-width: 53.5rem;
}

.live__img {
  position: relative;
  max-width: 62.4rem;
}

.live__img::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  width: 50rem;
  height: 30rem;
  background-color: rgba(112, 80, 143, 0.4);
  filter: blur(160px);
  transform: rotate(15.06deg) translate(-50%, -50%);
}

.methods__list {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 3rem;
  margin-bottom: 3.5rem;
}

.methods__item {
  border-radius: 2.2rem;
  padding: 1rem;
  min-height: 26rem;
  box-shadow: -15px 30px 40px rgba(14, 8, 17, 0.6);
  background-color: var(--clr-primary);
}

.methods__item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  min-height: 10rem;
  background-color: var(--clr-bg);
}

.methods__item-text {
  padding-right: 1rem;
  padding-left: 1rem;
}

.methods__img {
  max-width: 7rem;
}

.support__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 2.2rem;
  padding: 5.5rem 5rem;
  min-height: 51rem;
  background-color: var(--clr-primary);
}

.support__info {
  z-index: 1;
  max-width: 62rem;
}

.support__title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 125%;
}

.support__img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  margin-top: 2rem;
  max-width: 49.5rem;
}

@media (max-width: 75em) {
  .menu__item + .menu__item {
    margin-left: 2.5rem;
  }

  .hero__img {
    max-width: 51rem;
  }

  .register__list {
    grid-gap: 1.5rem;
  }

  .specifications__info {
    max-width: 50rem;
  }

  .reviews__list {
    grid-gap: 1.5rem;
  }

  .methods__list {
    grid-gap: 1.5rem;
  }
}

@media (max-width: 62em) {
  .faq__list {
    margin-top: 4rem;
  }

  .hero {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  .hero__info {
    max-width: initial;
  }

  .hero__img {
    display: none;
  }

  .info__title {
    margin-bottom: 2rem;
  }

  .info__table-left {
    padding: 1.5rem 2.5rem;
  }

  .info__table-right {
    padding: 1.5rem 2.5rem;
  }

  .review__wrapper {
    flex-direction: column;
  }

  .review__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .review__text {
    margin-bottom: 3rem;
  }

  .pros__item {
    padding: 9.5rem 3rem 3rem;
  }

  .register__item {
    padding: 10rem 1.5rem 1.5rem;
    min-height: 18rem;
  }

  .register__item::before {
    left: 1.5rem;
    top: 2rem;
  }

  .bonus__table td {
    padding: 1.5rem;
  }

  .version__wrapper {
    flex-direction: column;
  }

  .version__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .reviews__list {
    grid-template-columns: repeat(3, 1fr);
  }

  .vip__wrapper {
    flex-direction: column;
  }

  .vip__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .games__wrapper {
    display: block;
  }

  .games__left {
    flex: initial;
    margin-right: initial;
  }

  .games__right {
    flex: initial;
    margin-top: 2rem;
  }

  .live__wrapper {
    flex-direction: column;
  }

  .live__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .methods__list {
    grid-template-columns: repeat(4, 1fr);
  }

  .support__wrapper {
    flex-direction: column;
    padding: 3.5rem;
  }

  .support__info {
    max-width: initial;
  }

  .support__img {
    position: initial;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 992px) {
  .review__img::before {
    display: none;
  }

  .pros__item::before {
    display: none;
  }

  .version__text {
    margin-bottom: 3rem;
  }

  .vip__img::before {
    display: none;
  }

  .live__img::before {
    display: none;
  }
}

@media (max-width: 36em) {
  .pros__item {
    padding: 9.5rem 2rem 2.5rem;
  }

  .register__list {
    display: block;
  }

  .register__item + .register__item {
    margin-top: 1.5rem;
  }

  .methods__list {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .support__wrapper {
    margin-left: -1.5rem;
    border-radius: initial;
    padding: 2rem;
    width: calc(100% + 3rem);
  }
}

@media (max-width: 68.75em) {
  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item + .menu__item {
    margin-left: initial;
    margin-top: 3rem;
  }

  .menu__link {
    font-size: clamp(
    2.2rem,
    2.0181818182rem + 0.5681818182vw,
    2.7rem
  );
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-200);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .hero__img {
    top: 50%;
    bottom: initial;
    max-width: 40rem;
    transform: translateY(-50%);
  }

  .specifications__wrapper {
    display: block;
  }

  .specifications__info {
    margin-bottom: 2rem;
    max-width: initial;
  }
}

@media (max-width: 47.9375em) {
  .section__pd--t65 {
    padding-top: 3rem;
  }

  .section__pd--b65 {
    padding-bottom: 3rem;
  }

  .section__mr--b40 {
    margin-bottom: 2.5rem;
  }

  .faq__list {
    margin-top: 2rem;
  }

  .faq__item-title {
    padding: 1.5rem 4.5rem 1.5rem 1.5rem;
  }

  .faq__item-title::before {
    top: 2.4rem;
    right: 1.5rem;
  }

  .faq__item-title::after {
    top: 1.5rem;
    right: 2.4rem;
  }

  .faq__item-body {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .faq__item--active .faq__item-body {
    padding-top: 1.5rem;
  }

  .faq__item + .faq__item {
    margin-top: 1.5rem;
  }

  .info {
    padding-top: 6rem;
  }

  .pros__wrapper {
    flex-direction: column;
  }

  .pros__text {
    margin-bottom: 3.5rem;
  }

  .pros__item + .pros__item {
    margin-top: 1.5rem;
  }

  .register__text {
    margin-bottom: 2rem;
  }

  .register__list {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2rem;
  }

  .specifications__table-left {
    padding: 1.5rem;
  }

  .specifications__table-right {
    padding: 1.5rem;
  }

  .installation__wrapper {
    padding: 2rem;
  }

  .installation__text {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .download__text {
    margin-top: 2rem;
  }

  .reviews__list {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2.5rem;
  }

  .reviews__item {
    padding: 1.5rem;
    min-height: 13rem;
  }

  .games .bonus__table td {
    padding: 1.5rem;
  }

  .methods__list {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 28.75em) {
  .reviews__list {
    display: block;
  }

  .reviews__item + .reviews__item {
    margin-top: 1.5rem;
  }

  .methods__list {
    display: block;
  }

  .methods__item + .methods__item {
    margin-top: 1.5rem;
  }
}

@media (hover) {
  .section__btn:hover {
    box-shadow: 0 15px 35px rgba(226, 164, 51, 0.2);
    transition: box-shadow var(--transition);
  }

  .menu__link:hover::before {
    width: 100%;
    transition: width var(--transition);
  }

  .download__link:hover::before {
    width: 100%;
    transition: width var(--transition);
  }
}