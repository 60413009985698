.download {
  &__text {
    margin-top: 4rem;

    @media (max-width: em(767)) {
      margin-top: 2rem;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    font-weight: 500;
    line-height: 100%;
    color: var(--clr-default-200);

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.2rem;
      width: 0;
      height: 0.2rem;
      background-color: var(--clr-default-200);
      transform: translateX(-50%);
      transition: width var(--transition);
    }

    @media (hover) {
      &:hover {
        &::before {
          width: 100%;
          transition: width var(--transition);
        }
      }
    }
  }
}
