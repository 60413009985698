.header {
  position: absolute;
  width: 100%;
  padding-top: 1rem;
  z-index: 10;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
