.version {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 57rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__text {
    margin-top: 2rem;
    margin-bottom: 5.5rem;

    @media (max-width: 992px) {
      margin-bottom: 3rem;
    }
  }

  &__img {
    max-width: 53.9rem;
  }
}
