.support {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 2.2rem;
    padding: 5.5rem 5rem;
    min-height: 51rem;
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      flex-direction: column;
      padding: 3.5rem;
    }

    @media (max-width: em(576)) {
      margin-left: -1.5rem;
      border-radius: initial;
      padding: 2rem;
      width: calc(100% + 3rem);
    }
  }

  &__info {
    z-index: 1;
    max-width: 62rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 125%;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin-top: 2rem;
    max-width: 49.5rem;

    @media (max-width: em(992)) {
      position: initial;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
