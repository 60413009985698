.live {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 53.5rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: relative;
    max-width: 62.4rem;

    // Circle
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      width: 50rem;
      height: 30rem;
      background-color: rgb(112 80 143 / 40%);
      filter: blur(160px);
      transform: rotate(15.06deg) translate(-50%, -50%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
