.specifications {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(1100)) {
      display: block;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 57rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(1100)) {
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__content {
    overflow: auto;
    border-radius: 3.5rem;
    padding: 1rem;
    background-color: var(--clr-primary);
  }

  &__table {
    min-width: 50rem;
    font-family: "Gilroy", sans-serif;
    font-size: 1.4rem;
    line-height: 135%;

    &-row {
      display: flex;

      &:not(:last-child) {
        .specifications__table-left,
        .specifications__table-right {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: calc(100% - 5rem);
            height: 0.1rem;
            background-color: rgb(255 255 255 / 8%);
            transform: translateX(-50%);
            pointer-events: none;
          }
        }
      }

      &:first-child {
        .specifications__table-left {
          border-top-left-radius: 3rem;
        }
      }

      &:last-child {
        .specifications__table-left {
          border-bottom-left-radius: 3rem;
        }
      }
    }

    &-left {
      padding: 1.5rem 3rem;
      width: 100%;
      max-width: 16.5rem;
      background-color: var(--clr-bg);

      @media (max-width: em(767)) {
        padding: 1.5rem;
      }
    }

    &-right {
      padding: 1.5rem 3rem;
      width: 100%;

      @media (max-width: em(767)) {
        padding: 1.5rem;
      }
    }
  }
}
