.vip {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 57rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: relative;
    max-width: 50.4rem;

    // Circle
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      width: 43rem;
      height: 25.5rem;
      background-color: rgb(116 40 100 / 70%);
      filter: blur(160px);
      transform: translate(-50%, -50%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
