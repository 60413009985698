.register {
  &__text {
    margin-top: 2rem;
    margin-bottom: 5rem;

    @media (max-width: em(767)) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    counter-reset: section;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 2rem;
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    border-radius: 2.2rem;
    padding: 13rem 3.5rem 3.5rem;
    min-height: 26rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 125%;
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      padding: 10rem 1.5rem 1.5rem;
      min-height: 18rem;
    }

    &::before {
      counter-increment: section;
      content: counter(section);
      position: absolute;
      left: 3.5rem;
      top: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1.2rem;
      width: 6rem;
      height: 6rem;
      box-shadow: 0 15px 35px rgb(226 164 51 / 20%);
      font-weight: 700;
      font-size: fluid-font(2.4rem, 2rem);
      color: #1f1f20;
      background-image: linear-gradient(109.45deg, #ffcb6c 22.4%, #e2a433 77.68%);

      @media (max-width: em(992)) {
        left: 1.5rem;
        top: 2rem;
      }
    }
  }

  &__item + &__item {
    @media (max-width: em(576)) {
      margin-top: 1.5rem;
    }
  }
}
