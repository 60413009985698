.games {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      display: block;
    }
  }

  &__left {
    flex: 0 1 43%;
    margin-right: 1.5rem;

    @media (max-width: em(992)) {
      flex: initial;
      margin-right: initial;
    }
  }

  &__right {
    flex: 0 1 54%;

    @media (max-width: em(992)) {
      flex: initial;
      margin-top: 2rem;
    }
  }

  &__undertitle {
    margin-bottom: 2.5rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 125%;
  }

  .bonus__table td {
    padding: 2rem 3rem;

    @media (max-width: em(767)) {
      padding: 1.5rem;
    }
  }
}
