.installation {
  &__wrapper {
    border-radius: 2.2rem;
    padding: 4.5rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    background-color: var(--clr-primary);

    @media (max-width: em(767)) {
      padding: 2rem;
    }
  }

  &__text {
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    @media (max-width: em(767)) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .section__btn {
    height: 4.7rem;
    min-width: 19.4rem;
  }
}
