.hero {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 17rem;
  background-color: #06050a;

  @media (max-width: em(992)) {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  &__info {
    z-index: 1;
    max-width: 57rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {
    font-weight: 700;
    font-size: fluid-font(4.2rem, 3.4rem);
  }

  &__text {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    // @media (max-width: em(767)) {
    //   margin-top: 2.5rem;
    //   margin-bottom: 2.5rem;
    // }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    max-width: 59rem;

    @media (max-width: em(1200)) {
      max-width: 51rem;
    }

    @media (max-width: em(1100)) {
      top: 50%;
      bottom: initial;
      max-width: 40rem;
      transform: translateY(-50%);
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
